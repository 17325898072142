import React from "react";

const ssl = () => {
  return (
    <div className="container mx-auto mt-20 px-4">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Service Level Statement</h1>
        <div className="mb-6">
          <p>
            <strong>Triple X Telcom</strong>
          </p>
          <p>P.O. Box 460068</p>
          <p>Lake Monroe, FL 32747</p>
          <p>
            Email:{" "}
            <a
              href="mailto:SSL@TripleXTelcom.com"
              className="text-blue-600 underline"
            >
              SSL@TripleXTelcom.com
            </a>
          </p>
        </div>

        <hr className="my-4" />

        <div className="space-y-4">
          <div>
            <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
            <p>
              This Service Level Statement (SLS) outlines the service
              commitments provided by Triple X Telcom, a premier call center and
              marketing firm dedicated to delivering exceptional support and
              marketing services. Our mission is to provide reliable,
              high-quality, and efficient solutions to meet the needs of our
              clients.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">2. Services Provided</h2>
            <ul className="list-disc list-inside ml-4 space-y-2">
              <li>
                <strong>Call Center Services:</strong> Comprehensive inbound and
                outbound call handling, customer support, technical assistance,
                and telemarketing.
              </li>
              <li>
                <strong>Marketing Services:</strong> Strategic marketing
                campaigns, lead generation, customer acquisition, and market
                research.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">
              3. Service Objectives
            </h2>
            <ul className="list-disc list-inside ml-4 space-y-2">
              <li>
                <strong>Customer Satisfaction:</strong> Achieve a customer
                satisfaction rating of at least 90%.
              </li>
              <li>
                <strong>Response Time:</strong> Ensure that 95% of customer
                inquiries are responded to within 24 hours.
              </li>
              <li>
                <strong>Service Availability:</strong> Maintain 99.9% uptime for
                our services.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">
              4. Performance Metrics
            </h2>
            <ul className="list-disc list-inside ml-4 space-y-2">
              <li>
                <strong>Call Handling Time:</strong> Average call handling time
                will be monitored to ensure efficiency.
              </li>
              <li>
                <strong>First Call Resolution:</strong> Target a first call
                resolution rate of 85%.
              </li>
              <li>
                <strong>Quality Assurance:</strong> Conduct regular quality
                assurance reviews to maintain service standards.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">
              5. Support and Escalation
            </h2>
            <p>For any issues or concerns, clients may contact us via:</p>
            <ul className="list-disc list-inside ml-4 space-y-2">
              <li>
                Email:{" "}
                <a
                  href="mailto:SSL@TripleXTelcom.com"
                  className="text-blue-600 underline"
                >
                  SSL@TripleXTelcom.com
                </a>
              </li>
              <li>Phone: [Insert Phone Number]</li>
            </ul>
            <p>
              Our support team is available [Insert Hours of Operation], and we
              have a structured escalation process to address and resolve any
              urgent matters promptly.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">
              6. Confidentiality and Data Security
            </h2>
            <p>
              We prioritize the confidentiality and security of client
              information. All data handling practices comply with relevant
              regulations and standards, ensuring that sensitive information is
              protected at all times.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">
              7. Review and Updates
            </h2>
            <p>
              This Service Level Statement will be reviewed annually or as
              needed to reflect any changes in our services or operational
              practices. Clients will be notified of any significant updates.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">
              8. Contact Information
            </h2>
            <p>
              For any questions or further information, please reach out to:
            </p>
            <p>
              <strong>Triple X Telcom</strong>
            </p>
            <p>P.O. Box 460068</p>
            <p>Lake Monroe, FL 32747</p>
            <p>
              Email:{" "}
              <a
                href="mailto:SSL@TripleXTelcom.com"
                className="text-blue-600 underline"
              >
                SSL@TripleXTelcom.com
              </a>
            </p>
            <p>Phone: [Insert Phone Number]</p>
          </div>

          <p className="mt-4 text-gray-600 text-sm">
            Disclaimer: This document is subject to change and should be
            reviewed periodically to ensure it aligns with current service
            offerings and standards.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ssl;
