// src/components/footer.jsx
import "./css/footer.css";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer text-white">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-4 px-4 py-10">
        {/* First Column */}
        <div className="column">
          <img
            src="assets/img/logo.jpg"
            alt="Logo"
            className="w-4/12 h-auto mb-4"
          />
          <p className="text-lg">XXX Telcom</p>
        </div>

        {/* Second Column */}
        <div className="column">
          <ul className="space-y-2">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/who-we-are">Who we are</Link>
            </li>
            <li>
              <Link to="/careers">Careers</Link>
            </li>
          </ul>
        </div>

        {/* Third Column */}
        <div className="column">
          <ul className="space-y-2">
            <li>
              <Link to="/position-descriptions">Position Descriptions</Link>
            </li>
            <li>
              <Link to="/apply-now">Apply Now</Link>
            </li>
            <li>
              <a href="http://dashboard.triplextelcom.com/login">Agent Login</a>
            </li>
            <li>
              <Link to="/management-login">Management Login</Link>
            </li>
          </ul>
        </div>

        {/* Fourth Column */}
        <div className="column">
          <ul className="space-y-2">
            <li>
              <Link to="/ssl">SSL</Link>
            </li>
            <li>
              <Link to="/cookies">Cookies</Link>
            </li>
            <li>
              <Link to="/terms-of-use">Terms of Use</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>

            <div className="column">
              <img
                src="assets/img/ssl.png"
                alt="Logo"
                className="w-4/12 h-auto mb-4"
              />
            </div>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
