import React from "react";
import Section5 from "../components/section5";
import Section9 from "../components/section9";
import Section10 from "../components/section10";
import Section6 from "../components/section6";
import Section3 from "../components/section3";

const career = () => {
  return (
    <div>
      <Section5 />
      <Section9 />
      <Section10 />
      <Section6 />
      <Section3 />
    </div>
  );
};

export default career;
