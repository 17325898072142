import React from "react";
import "./css/section5.css";

function Section5() {
  return (
    <div className="section5 text-white">
      <section className="px-4 py-10 md:py-20">
        <div className="w-full max-w-7xl mx-auto">
          <dl className="-mx-4 flex flex-wrap flex-row-reverse">
            <dt
              className="w-full md:w-1/2 p-4 wow animate__fadeInRight"
              data-wow-duration="3s"
              style={{
                visibility: "visible",
                animationduration: "3s",
                animationname: "fadeInRight",
              }}
            >
              <div className="w-full h-[420px] lg:h-[750px] relative overflow-hidden">
                <img
                  src="assets/img/1.jpg"
                  className="w-[140px] lg:w-[289px] h-auto absolute left-0 top-20 wow animate__bounceInLeft"
                  data-wow-duration="3s"
                  style={{
                    visibility: "visible",
                    animationduration: "3s",
                    animationname: "bounceInLeft",
                  }}
                />
                <img
                  src="assets/img/2.jpg"
                  className="w-[140px] lg:w-[225px] h-auto absolute left-5 bottom-10 lg:bottom-20 wow animate__bounceInLeft"
                  data-wow-duration="3s"
                  style={{
                    visibility: "visible",
                    animationduration: "3s",
                    animationname: "bounceInLeft",
                  }}
                />
                <div className="absolute right-0 top-1/2 -translate-y-1/2">
                  <img
                    src="assets/img/3.jpg"
                    className="w-[200px] lg:w-[390px] h-auto wow animate__bounceInRight"
                    data-wow-duration="3s"
                    style={{
                      visibility: "visible",
                      animationduration: "3s",
                      animationname: "bounceInRight",
                    }}
                  />
                </div>
                <img
                  src="assets/img/7.jpg"
                  className="w-[215px] lg:w-[415px] h-auto absolute right-5 bottom-0 wow animate__bounceInDown"
                  data-wow-duration="3s"
                  style={{
                    visibility: "visible",
                    animationduration: "3s",
                    animationname: "bounceInDown",
                  }}
                />
                <img
                  src="assets/img/5.jpg"
                  className="w-[250px] lg:w-[450px] h-auto absolute right-0 top-0 wow animate__bounceInUp"
                  data-wow-duration="3s"
                  style={{
                    visibility: "visible",
                    animationduration: "3s",
                    animationname: "bounceInUp",
                  }}
                />
              </div>
            </dt>
            <dd
              className="w-full md:w-1/2 p-4 wow animate__fadeInLeft"
              data-wow-duration="3s"
              style={{
                visibility: "visible",
                animationduration: "3s",
                animationname: "fadeInLeft",
              }}
            >
              <h5 className="text-xl md:text-3xl font-light text-center">
                WHO WE ARE
              </h5>
              <h6 className="text-2xl md:text-5xl text-[#7CB8EB] font-bold text-center"></h6>
              <article
                className="mt-5 md:mt-10 text-lg md:text-2xl font-bold text-gray-default"
                text-justify
              >
                <p>
                  XXX Telcom is a work-at-home call center company dedicated to
                  providing exceptional service to both our clients and
                  employees. As a grassroots company, we are committed to
                  creating a supportive and flexible work environment that
                  allows our team members to thrive while delivering top-notch
                  customer service. Our innovative approach to remote work
                  ensures that our clients receive reliable and efficient
                  support, tailored to meet their unique needs.
                </p>
                <br />
                <p>
                  We offer a range of benefits designed to enhance the
                  well-being and productivity of our employees. By prioritizing
                  the needs of our workforce, we foster a culture of mutual
                  respect and collaboration. At XXX Telcom, we believe that the
                  success of our company is rooted in the satisfaction and
                  growth of our team, and we strive to create opportunities for
                  professional development and personal fulfillment for everyone
                  involved.
                </p>
              </article>
              <div className="mt-5 md:mt-10 flex justify-center">
                <a
                  href="javascript:;"
                  onclick="openApplyPopup()"
                  className="apply-btn w-full h-14 md:w-auto md:px-10 bg-[#7CB8EB] text-white rounded-full flex items-center justify-center"
                >
                  <a href="/who-we-are" className="md:text-lg">
                    Find out more about us
                  </a>
                </a>
              </div>
            </dd>
          </dl>
        </div>
      </section>
    </div>
  );
}

export default Section5;
