import React from "react";
import "./css/section4.css";

function Section1() {
  return (
    <div className="section4">
      <section className="px-4 py-10 md:py-20">
        <div className="w-full max-w-7xl mx-auto">
          <dl className="-mx-4 text-xl md:text-3xl font-bold flex flex-wrap items-center">
            <dd className="w-full md:w-1/2 px-4 lg:pr-10 text-white">
              Our Team is in the business of making the complicated seem simple.
            </dd>
            <dd className="w-full md:w-1/2 px-4 text-white">
              Our recruitment is not like the rest. <br />
              You are a one of a kind talent. <br />
              We will treat you unlike no other. <br />
              You know your value, and so do we at XXX Telcom.
            </dd>
          </dl>
          <div className="overflow-x-hidden">
            <ul className="-mx-6 flex flex-wrap">
              <li
                className="w-full md:w-1/3 p-6 wow animate__fadeInUp"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationduration: "2s",
                  animationname: "fadeInUp",
                }}
              >
                <div className="w-full h-full bg-default text-default rounded-2xl p-4 md:p-6">
                  <img
                    src="https://www.sino-ocean-remote-work.com/imgs/step-1.png"
                    className="w-24 h-24 mb-3 mx-auto object-cover block"
                  />
                  <h3 className="mb-5 text-xl md:text-3xl font-bold text-center">
                    Client Driven
                  </h3>
                  <article className="text-gray-default text-center font-bold">
                    <p>
                      We know when a client comes knocking on the doors of XXX
                      Telcom, they are looking for an extraordinary call center
                      team.
                    </p>
                  </article>
                </div>
              </li>
              <li
                className="w-full md:w-0 relative wow animate__fadeInUp"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationduration: "2s",
                  animationname: "fadeInUp",
                }}
              >
                <em className="w-full md:w-12 h-12 md:h-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
                  <svg
                    className="w-12 h-12 rotate-90 md:rotate-0"
                    stroke="#acb8c0"
                    viewBox="0 0 47.35603535000001 16.0"
                  >
                    <path
                      d="M2,8 L44.35603535,8"
                      stroke-width="4px"
                      stroke-linecap="round"
                    ></path>
                    <path
                      d="M37.35603535,2 L45.35603535,8 L37.35603535,14"
                      stroke-width="4px"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </em>
              </li>
              <li
                className="w-full md:w-1/3 p-6 wow animate__fadeInUp"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationduration: "2s",
                  animationname: "fadeInUp",
                }}
              >
                <div className="w-full h-full bg-default text-default rounded-2xl p-4 md:p-6">
                  <img
                    src="https://www.sino-ocean-remote-work.com/imgs/step-2.svg"
                    className="w-24 h-24 mb-3 mx-auto object-cover block"
                  />
                  <h3 className="mb-5 text-xl md:text-3xl font-bold text-center">
                    Purpose Focused
                  </h3>
                  <article className="text-gray-default text-center font-bold">
                    <p>
                      Our focus is you. The client, agent, and project. Without
                      three focuses, there Would be no XXX Telcom.
                    </p>
                  </article>
                </div>
              </li>
              <li
                className="w-full md:w-0 relative wow animate__fadeInUp"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationduration: "2s",
                  animationname: "fadeInUp",
                }}
              >
                <em className="w-full md:w-12 h-12 md:h-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
                  <svg
                    className="w-12 h-12 rotate-90 md:rotate-0"
                    stroke="#acb8c0"
                    viewBox="0 0 47.35603535000001 16.0"
                  >
                    <path
                      d="M2,8 L44.35603535,8"
                      stroke-width="4px"
                      stroke-linecap="round"
                    ></path>
                    <path
                      d="M37.35603535,2 L45.35603535,8 L37.35603535,14"
                      stroke-width="4px"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </em>
              </li>
              <li
                className="w-full md:w-1/3 p-6 wow animate__fadeInUp"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationduration: "2s",
                  animationname: "fadeInUp",
                }}
              >
                <div className="w-full h-full bg-default text-default rounded-2xl p-4 md:p-6">
                  <img
                    src="https://www.sino-ocean-remote-work.com/imgs/step-3.svg"
                    className="w-24 h-24 mb-3 mx-auto object-cover block"
                  />
                  <h3 className="mb-5 text-xl md:text-3xl font-bold text-center">
                    All Around Effective Solutions
                  </h3>
                  <article className="text-gray-default text-center font-bold">
                    <p>
                      We are not a cookie-cutter company. Our services come from
                      a grassroots campaign approach. Knowing the assignment
                      leaves us providing State-of-the-art solutions.
                    </p>
                  </article>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Section1;
