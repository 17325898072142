// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AppCss from "./App.css";
import Menu from "./components/menu";
import Home from "./pages/home";
import WhoWeAre from "./pages/who";
import Careers from "./pages/career";
import Ssl from "./pages/ssl";
import Cookies from "./pages/cookies";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";

import Footer from "./components/footer";

function App() {
  return (
    <Router>
      <div className="App">
        <Menu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/ssl" element={<Ssl />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/terms-of-use" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
