import React, { useState } from "react";
import ApplicationFormModal from "./ApplicationFormModal";
import "./css/section2.css";

function Section1() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <div className="section2">
      <section id="form" className="px-4 py-10 md:py-20 text-white relative">
        <em
          className="w-full h-full bg-no-repeat bg-center bg-cover absolute left-0 top-0 block wow animate__uislide"
          style={{
            backgroundImage: 'url("assets/img/6.jpg")',
            visibility: "visible",
            animationName: "uiscale",
          }}
        ></em>
        <i className="w-full h-full bg-black/30 absolute left-0 top-0 block"></i>
        <div className="w-full max-w-7xl mx-auto py-20 relative z-10">
          <dl className="flex flex-wrap">
            <dt className="w-full md:w-1/2">
              <h2
                className="main-title ctx-title lg:!text-7xl font-bold text-center md:text-left wow animate__uislide"
                data-wow-delay="1s"
                style={{
                  visibility: "visible",
                  animationDelay: "1s",
                  animationName: "uiscale",
                }}
              >
                XXX Telcom
              </h2>
              <div
                className="w-full max-w-2xl mt-5 text-lg md:text-3xl text-left wow animate__uislide"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "uiscale",
                }}
              >
                <p className="sub-title">
                  Is designed for you <br />
                  by people with a mind like you.
                </p>
                <p className="telcomColor">We offer:</p>
                <ul>
                  <li>Flexible Hours</li>
                  <li>Great Pay</li>
                  <li>Health Insurance</li>
                </ul>
              </div>
            </dt>
            <dd className="w-full md:w-1/2 pt-5 md:pt-0 flex justify-end items-center">
              <a
                href="#"
                // Assuming you have a function called openApplyPopup()
                className="w-[185px] h-[185px] ctx-theme_linear rounded-full flex items-center justify-center wow animate__uislide"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "uiscale",
                }}
              >
                <div className="text-2xl text-center text-apply">
                  <button onClick={openModal} className="open-modal-button">
                    Apply Now
                  </button>
                  <ApplicationFormModal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                  />
                </div>
              </a>
            </dd>
          </dl>
        </div>
      </section>
    </div>
  );
}

export default Section1;
