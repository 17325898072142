import React from "react";
import "./css/section10.css";

function Section10() {
  return (
    <div className="section10 text-white">
      <section className="px-4 py-10 md:py-20">
        <div className="w-full max-w-7xl mx-auto">
          <dl className="flex flex-wrap items-center">
            <dt className="w-full md:w-1/2">
              <div
                className="text-2xl md:text-3xl lg:text-4xl font-bold wow animate__uislide"
                data-wow-duration="5s"
                style={{
                  visibility: "visible",
                  animationDuration: "5s",
                  animationName: "uiscale",
                }}
              >
                <p>ANY THOUGHTS</p>
                <p>OR</p>
                <p>SUGGESTIONS?</p>
              </div>
            </dt>
            <dd className="w-full md:w-1/2 flex md:justify-end">
              <div
                className="text-xl md:text-3xl font-bold wow animate__uislide"
                data-wow-duration="5s"
                style={{
                  visibility: "visible",
                  animationDuration: "5s",
                  animationName: "uiscale",
                }}
              >
                <p>Contact Us by</p>
                <p>Click here</p>
              </div>
            </dd>
          </dl>
        </div>
      </section>
    </div>
  );
}

export default Section10;
