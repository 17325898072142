import React from "react";
import "./css/section9.css";

function Section9() {
  return (
    <div className="section9 text-white">
      <section className="px-4 py-10 md:py-20 relative">
        <em
          className="w-full h-full absolute left-0 top-0 bg-no-repeat bg-center bg-cover wow animate__uislide"
          data-wow-duration="5s"
          style={{
            backgroundImage: `url("assets/img/9.jpg")`,
            visibility: "visible",
            animationDuration: "5s",
            animationName: "uiscale",
          }}
        ></em>
        <div className="w-full max-w-7xl mx-auto text-white relative z-10">
          <h2
            className="sec-title ctx-title mb-3 font-bold wow animate__uislide"
            data-wow-duration="5s"
            style={{
              visibility: "visible",
              animationDuration: "5s",
              animationName: "uiscale",
            }}
          >
            Join the XXX Family Now
          </h2>
          <p
            className="ctx-tips opacity-70 wow animate__uislide text-xl"
            data-wow-duration="5s"
            style={{
              visibility: "visible",
              animationDuration: "5s",
              animationName: "uiscale",
              fontWeight: 700,
            }}
          >
            Click on the Link Below to get started
          </p>
          <div className="lg:pl-20 pt-5 flex">
            <a
              href="javascript:void(0);"
              //   onClick={() => openApplyPopup()}
              className="w-full md:w-auto h-16 md:px-10 border-[3px] border-orange-500 bg-orange-100 text-black rounded-lg flex items-center justify-center wow animate__bounceIn"
              data-wow-duration="5s"
              style={{
                visibility: "visible",
                animationDuration: "5s",
                animationName: "bounceIn",
              }}
            >
              <b className="text-xl md:text-3xl font-medium">Let's start</b>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Section9;
