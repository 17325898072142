import React from "react";
import "./css/section3.css";

function Section3() {
  return (
    <div className="section3 text-white">
      <section className="px-4 py-10 md:py-20">
        <div className="w-full max-w-7xl mx-auto">
          <div className="text-center">
            <h2
              className="sec-title ctx-title wow animate__uislide"
              data-wow-duration="5s"
              style={{
                visibility: "visible",
                animationDuration: "5s",
                animationName: "uiscale",
              }}
            >
              OUR PARTNERS & CUSTOMERS
            </h2>
            <p
              className="sec-subtitle mt-3 ctx-tips opacity-70 wow animate__uislide"
              data-wow-duration="5s"
              style={{
                visibility: "visible",
                animationDuration: "5s",
                animationName: "uiscale",
              }}
            >
              We focus on community projects that help Individuals & Families
            </p>
          </div>
          <ul className="mt-5 md:mt-10 flex items-center">
            <li className="w-1/4 flex justify-center">
              <img
                src="assets/img/partner1.jpg"
                className="w-full max-w-[260px] h-auto wow animate__uislide"
                style={{ visibility: "visible", animationName: "uiscale" }}
                alt="Partner 1"
              />
            </li>
            <li className="w-1/4 flex justify-center">
              <img
                src="assets/img/partner2.jpg"
                className="w-full max-w-[260px] h-auto wow animate__uislide"
                style={{ visibility: "visible", animationName: "uiscale" }}
                alt="Partner 2"
              />
            </li>
            <li className="w-1/4 flex justify-center">
              <img
                src="assets/img/partner3.jpg"
                className="w-full max-w-[260px] h-auto wow animate__uislide"
                style={{ visibility: "visible", animationName: "uiscale" }}
                alt="Partner 3"
              />
            </li>
            <li className="w-1/4 flex justify-center">
              <img
                src="assets/img/partner4.jpg"
                className="w-full max-w-[260px] h-auto wow animate__uislide"
                style={{ visibility: "visible", animationName: "uiscale" }}
                alt="Partner 4"
              />
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default Section3;
