import React from "react";
const terms = () => {
  return (
    <div className="container mx-auto mt-20 px-4">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Terms of Use</h1>
        <div className="mb-6">
          <p>
            <strong>Triple X Telcom</strong>
          </p>
          <p>P.O. Box 460068</p>
          <p>Lake Monroe, FL 32747</p>
          <p>
            Email:{" "}
            <a
              href="mailto:TermsofUse@TripleXTelcom.com"
              className="text-blue-600 underline"
            >
              TermsofUse@TripleXTelcom.com
            </a>
          </p>
        </div>

        <hr className="my-4" />

        <div className="space-y-4">
          <div>
            <h2 className="text-xl font-semibold mb-2">
              1. Acceptance of Terms
            </h2>
            <p>
              By accessing or using the Triple X Telcom website and services,
              you agree to comply with and be bound by these{" "}
              <strong>Terms of Use</strong>. If you do not agree with these
              terms, please do not use our website or services.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">2. Changes to Terms</h2>
            <p>
              We reserve the right to modify these Terms of Use at any time. Any
              changes will be effective when posted on this website with an
              updated effective date. Your continued use of our website or
              services after such changes constitutes acceptance of the new
              terms.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">3. Use of Services</h2>
            <ul className="list-disc list-inside ml-4 space-y-2">
              <li>
                <strong>Eligibility:</strong> You must be at least 18 years old
                or have the consent of a parent or guardian to use our services.
              </li>
              <li>
                <strong>User Responsibilities:</strong> You agree to use our
                website and services for lawful purposes only and in accordance
                with these Terms of Use. You agree not to engage in any
                activities that may harm, disrupt, or interfere with the
                operation of our services or the experience of other users.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">
              4. Intellectual Property
            </h2>
            <p>
              All content, trademarks, and other intellectual property on our
              website are owned by Triple X Telcom or our licensors. You may not
              reproduce, distribute, modify, or otherwise use any content from
              our website without our prior written consent.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">5. User Content</h2>
            <ul className="list-disc list-inside ml-4 space-y-2">
              <li>
                <strong>Submission:</strong> If you submit content to us (such
                as feedback, comments, or other materials), you grant us a
                non-exclusive, royalty-free, perpetual, and worldwide license to
                use, reproduce, modify, and display such content for any
                purpose.
              </li>
              <li>
                <strong>Responsibility:</strong> You are solely responsible for
                any content you submit and ensure it does not infringe on any
                third-party rights or violate any laws.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">
              6. Limitation of Liability
            </h2>
            <p>
              To the fullest extent permitted by law, Triple X Telcom will not
              be liable for any indirect, incidental, special, consequential, or
              punitive damages arising out of or related to your use of our
              website or services. Our total liability for any claim related to
              our services shall not exceed the amount paid by you for those
              services.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">
              7. Disclaimer of Warranties
            </h2>
            <p>
              Our website and services are provided on an "as-is" and
              "as-available" basis. We make no warranties, express or implied,
              regarding the accuracy, reliability, or availability of our
              website or services. We disclaim all warranties to the extent
              permitted by law.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">8. Third-Party Links</h2>
            <p>
              Our website may contain links to third-party websites or services.
              These links are provided for your convenience and do not imply
              endorsement or responsibility for the content, policies, or
              practices of these third parties. You access third-party sites at
              your own risk.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">9. Termination</h2>
            <p>
              We reserve the right to terminate or suspend your access to our
              website and services, without notice, for any reason, including if
              we believe you have violated these Terms of Use.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">10. Governing Law</h2>
            <p>
              These Terms of Use are governed by and construed in accordance
              with the laws of the State of Florida, without regard to its
              conflict of laws principles. Any disputes arising from these terms
              shall be resolved in the state or federal courts located in Lake
              Monroe, FL.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">
              11. Contact Information
            </h2>
            <p>
              For any questions or concerns regarding these Terms of Use, please
              contact us at:
            </p>
            <p>
              {" "}
              <br />
              <br />
              <strong>Triple X Telcom</strong>
            </p>
            <p>P.O. Box 460068</p>
            <p>Lake Monroe, FL 32747</p>
            <p>
              Email:{" "}
              <a
                href="mailto:TermsofUse@TripleXTelcom.com"
                className="text-blue-600 underline"
              >
                TermsofUse@TripleXTelcom.com
              </a>
            </p>
          </div>

          <p className="mt-4 text-gray-600 text-sm">
            Disclaimer: These Terms of Use are subject to change and should be
            reviewed periodically to ensure they remain current with legal
            requirements and company policies.
          </p>
        </div>
      </div>
    </div>
  );
};

export default terms;
