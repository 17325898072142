import React from "react";
import { Link, NavLink } from "react-router-dom";

const menu = () => {
  return (
    <div>
      <menu
        id="header"
        className="w-full px-4 text-white fixed left-0 top-0 z-30"
      >
        <div className="w-full max-w-7xl h-20 mx-auto flex items-center justify-between">
          <a href="/">
            <div className="flex items-center flex-shrink-0">
              <img
                src="assets/img/logo.jpg"
                className="w-auto h-5 sm:h-8 md:h-[50px] mr-2"
              />
              <b className="text-xs sm:text-sm md:text-2xl">XXX Telcom</b>
            </div>
          </a>
          <div className="flex-1 overflow-hidden"></div>

          <NavLink
            to="/"
            className={({ isActive }) =>
              `px-2 sm:px-3 lg:px-4 md:py-2 text-white rounded-full text-center block ${
                isActive ? "bg-[#000000]" : "bg-[#870404]"
              }`
            }
          >
            Home
          </NavLink>
          <NavLink
            to="who-we-are"
            className={({ isActive }) =>
              `px-2 sm:px-3 lg:px-4 md:py-2 text-white rounded-full text-center block ${
                isActive ? "bg-[#000000]" : "bg-[#870404]"
              }`
            }
          >
            WHO WE ARE
          </NavLink>

          <NavLink
            to="careers"
            className={({ isActive }) =>
              `px-2 sm:px-3 lg:px-4 md:py-2 text-white rounded-full text-center block ${
                isActive ? "bg-[#000000]" : "bg-[#870404]"
              }`
            }
          >
            CAREERS
          </NavLink>
        </div>
      </menu>
    </div>
  );
};

export default menu;
