import React from "react";

const privacy = () => {
  return (
    <div className="container mx-auto mt-20 px-4">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
        <p className="mb-4">
          <strong>Triple X Telcom</strong> <br />
          P.O. Box 460068 <br />
          Lake Monroe, FL 32747 <br />
          Email: privacy@TripleXTelcom.com
        </p>
        <hr />
        <h2 className="text-xl font-semibold mb-3">1. Introduction</h2>
        <p className="mb-4">
          Welcome to Triple X Telcom. We value your privacy and are committed to
          protecting your personal information. This Privacy Policy outlines how
          we collect, use, disclose, and safeguard your information when you
          visit our website, use our services, or engage with us.
        </p>
        <h2 className="text-xl font-semibold mb-3">
          2. Information We Collect
        </h2>
        <p className="mb-4 pl-10">
          <strong>a. Personal Information: </strong> We may collect personal
          information that you provide to us directly, such as your name,
          contact details, and any other information you choose to share with
          us.
        </p>
        <p className="mb-4 pl-10">
          <strong>b. Usage Data: </strong> We collect information about how you
          interact with our website and services, including IP addresses,
          browser types, and pages visited. This helps us understand how our
          services are used and improve them.
        </p>
        <p className="mb-4 pl-10">
          <strong>c. Cookies and Tracking Technologies: </strong> We use cookies
          and similar technologies to enhance your experience on our site.
          Cookies are small data files stored on your device that help us
          remember your preferences and track usage patterns.
        </p>

        <h2 className="text-xl font-semibold mb-3">
          3. How We Use Your Information
        </h2>
        <p className="mb-4 pl-10">
          We use the information we collect for the following purposes: <br />
          <strong>To Provide Services: </strong> To deliver the services you
          request, including call center and marketing services.
        </p>
        <p className="mb-4 pl-10">
          <strong>To Improve Our Services: </strong> To analyze usage patterns
          and improve our offerings.
        </p>
        <p className="mb-4 pl-10">
          <strong>To Communicate: </strong> To send you updates, promotional
          materials, and other information related to our services.
        </p>
        <p className="mb-4 pl-10">
          <strong>To Communicate: </strong> To handle billing and other
          financial transactions.
        </p>
        <p className="mb-4 pl-10">
          <strong>To Ensure Security: </strong> To protect against fraud,
          unauthorized transactions, and other potential threats.
        </p>

        <h2 className="text-xl font-semibold mb-3">
          4. Information Sharing and Disclosure
        </h2>
        <p className="mb-4 pl-10">
          We do not sell or rent your personal information to third parties.
          However, we may share your information in the following situations:
        </p>

        <h2 className="text-xl font-semibold mb-3">5. Data Security</h2>
        <p className="mb-4 pl-10">
          We implement a range of security measures to protect your personal
          information from unauthorized access, disclosure, alteration, and
          destruction. While we strive to safeguard your information, no method
          of transmission over the internet or electronic storage is completely
          secure.
        </p>
        <ul className="list-disc list-inside space-y-2 pl-10">
          <li>
            <strong>With Service Providers:</strong> We may share information
            with third-party service providers who assist us in operating our
            business and delivering our services. These providers are
            contractually obligated to protect your information.
          </li>
          <li>
            <strong>For Legal Reasons:</strong> We may disclose information if
            required by law or to protect our rights, property, or safety, or
            the rights, property, or safety of others.
          </li>
          <li>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or other business change, your information may be
            transferred to a new entity as part of that transaction.
          </li>
        </ul>
        <h2 className="text-xl font-semibold mb-3">
          6. Your Rights and Choices
        </h2>

        <p className="mb-4 pl-10">
          You have the following rights regarding your personal information:
        </p>
        <ul className="list-disc list-inside space-y-2 pl-10">
          <li>
            <strong>Access and Correction: </strong> You can request access to
            or correction of your personal information by contacting us.
          </li>
          <li>
            <strong>Opt-Out: </strong> You may opt out of receiving promotional
            communications from us by following the unsubscribe instructions in
            those communications.
          </li>
          <li>
            <strong>Data Deletion: </strong> You may request the deletion of
            your personal information, subject to legal and contractual
            obligations.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-3">
          7. Changes to This Privacy Policy
        </h2>
        <p className="mb-4 pl-10">
          We may update this Cookies Policy from time to time. Any changes will
          be posted on our website with an updated effective date. We encourage
          you to review this policy periodically to stay informed about how we
          use cookies.
        </p>

        <h2 className="text-xl font-semibold mb-3">8. Contact Us</h2>
        <p className="pl-10 mb-4">
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at: <br />
          <br />
          <strong>Triple X Telcom</strong> <br />
          P.O. Box 460068 <br />
          Lake Monroe, FL 32747 <br />
          Email:{" "}
          <a
            href="mailto:cookies@TripleXTelcom.com"
            className="text-blue-500 underline"
          >
            privacy@TripleXTelcom.com
          </a>
        </p>
        <hr />
        <p className="mt-4">
          <strong>Disclaimer:</strong> This Privacy Policy is subject to change
          and should be reviewed periodically to ensure it aligns with current
          legal requirements and company practices.
        </p>
      </div>
    </div>
  );
};

export default privacy;
