import React from "react";
import "./css/section6.css";

function Section6() {
  return (
    <div classNameName="section6">
      <section className="px-4 py-10 md:py-20 relative text-white">
        <em
          className="w-full h-full bg-no-repeat bg-center bg-cover absolute left-0 top-0 block wow animate__bounceInLeft"
          data-wow-duration="2s"
          style={{
            backgroundImage: "url('assets/img/4.jpg')",
            visibility: "visible",
            animationDuration: "4s",
            animationName: "bounceInLeft",
          }}
        ></em>

        <i className="w-full h-full bg-black/40 absolute left-0 top-0 block"></i>
        <div className="w-full max-w-7xl mx-auto relative">
          <dl className="-mx-4 flex flex-wrap">
            <dt
              className="w-full md:w-3/5 p-4 wow animate__bounceInLeft"
              data-wow-duration="4s"
              style={{
                visibility: "visible",
                animationduration: "4s",
                animationname: "bounceInLeft",
              }}
            >
              <h2 className="sec-title ctx-title text-center font-bold">
                <span>Are you ready to work Remote?</span>
              </h2>
              <p className="mt-5 text-lg md:text-2xl text-center font-bold">
                We have immediate positions waiting for you.
              </p>
            </dt>
            <dd className="w-full md:w-2/5 p-4">
              <div
                className="h-full flex items-center justify-center wow animate__bounceInRight"
                data-wow-duration="4s"
                style={{
                  visibility: "visible",
                  animationduration: "4s",
                  animationname: "bounceInRight",
                }}
              >
                <a
                  href="javascript:;"
                  onclick="openApplyPopup()"
                  className="apply-btn w-full md:w-auto h-12 md:px-6 ctx-theme_linear rounded-full flex items-center justify-center"
                >
                  <span className="md:text-lg">See Available Positions</span>
                </a>
              </div>
            </dd>
          </dl>
        </div>
      </section>
    </div>
  );
}

export default Section6;
