import React from "react";

function cookies() {
  return (
    <div className="container mx-auto mt-20 px-4">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Cookies Policy</h1>
        <p className="mb-4">
          <strong>Triple X Telcom</strong> <br />
          P.O. Box 460068 <br />
          Lake Monroe, FL 32747 <br />
          Email: cookies@TripleXTelcom.com
        </p>
        <hr />
        <h2 className="text-xl font-semibold mb-3">1. Introduction</h2>
        <p className="mb-4">
          This Cookies Policy explains how Triple X Telcom uses cookies and
          similar tracking technologies on our website. By using our website,
          you consent to the use of cookies in accordance with this policy.
        </p>
        <h2 className="text-xl font-semibold mb-3">2. What Are Cookies?</h2>
        <p className="mb-4">
          Cookies are small data files placed on your device (computer,
          smartphone, or tablet) when you visit a website. They are used to
          remember your preferences, enhance your browsing experience, and help
          us understand how our website is used.
        </p>
        <h2 className="text-xl font-semibold mb-3">
          3. Types of Cookies We Use
        </h2>
        <p className="mb-4 pl-10">
          We use the following types of cookies: <br />
          <strong>a. Essential Cookies:</strong> These cookies are necessary for
          the website to function properly. They enable basic features such as
          page navigation and access to secure areas of the site.
        </p>
        <p className="mb-4 pl-10">
          <strong>b. Performance Cookies:</strong> These cookies collect
          information about how visitors use our website, such as which pages
          are visited most often. This helps us improve the performance and
          functionality of our site.
        </p>
        <p className="mb-4 pl-10">
          <strong>c. Functional Cookies:</strong> These cookies remember your
          preferences and settings to enhance your user experience. For example,
          they may remember your language preference or login details.
        </p>
        <p className="mb-4 pl-10">
          <strong>d. Advertising Cookies:</strong> These cookies are used to
          deliver advertisements that are relevant to you and your interests.
          They help us measure the effectiveness of our advertising campaigns.
        </p>

        <h2 className="text-xl font-semibold mb-3">4. How We Use Cookies</h2>
        <p className="mb-4 pl-10">
          We use cookies for the following purposes: <br />
          <strong>To Improve Our Website: </strong> By analyzing how you use our
          website, we can enhance its functionality and performance.
        </p>
        <p className="mb-4 pl-10">
          To Personalize Your Experience: <br />
          <strong>To Improve Our Website: </strong> Cookies help us remember
          your preferences and settings, making your experience more
          personalized.
        </p>
        <p className="mb-4 pl-10">
          To Provide Relevant Advertising: <br />
          <strong>To Improve Our Website: </strong> Cookies allow us to deliver
          targeted advertisements based on your interests and browsing history.
        </p>

        <h2 className="text-xl font-semibold mb-3">5. Third-Party Cookies</h2>
        <p className="mb-4 pl-10">
          Our website may also use third-party cookies from partners and service
          providers. These cookies may be used for various purposes, including
          analytics, advertising, and social media features. We do not control
          these third-party cookies, and their use is governed by the privacy
          policies of the respective third parties.
        </p>

        <h2 className="text-xl font-semibold mb-3">6. Managing Cookies</h2>
        <p className="mb-4 pl-10">
          You can manage and control cookies through your browser settings. Most
          web browsers allow you to refuse or delete cookies. However, disabling
          cookies may affect your ability to use certain features of our
          website.
        </p>
        <p>
          For instructions on how to manage cookies in popular browsers, visit
          the following links:
        </p>
        <ul className="list-disc list-inside space-y-2 pl-10">
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=en"
              className="text-blue-500 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Chrome: Manage Cookies in Chrome
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
              className="text-blue-500 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mozilla Firefox: Manage Cookies in Firefox
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy"
              className="text-blue-500 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Microsoft Edge: Manage Cookies in Edge
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              className="text-blue-500 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Safari: Manage Cookies in Safari
            </a>
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-3">
          7. Changes to This Cookies Policy
        </h2>
        <p className="mb-4 pl-10">
          We may update this Cookies Policy from time to time. Any changes will
          be posted on our website with an updated effective date. We encourage
          you to review this policy periodically to stay informed about how we
          use cookies.
        </p>

        <h2 className="text-xl font-semibold mb-3">8. Contact Us</h2>
        <p className="pl-10 mb-4">
          If you have any questions or concerns about this Cookies Policy or our
          use of cookies, please contact us at: <br />
          <br />
          <strong>Triple X Telcom</strong> <br />
          P.O. Box 460068 <br />
          Lake Monroe, FL 32747 <br />
          Email:{" "}
          <a
            href="mailto:cookies@TripleXTelcom.com"
            className="text-blue-500 underline"
          >
            cookies@TripleXTelcom.com
          </a>
        </p>
        <hr />
        <p className="mt-4">
          <strong>Disclaimer:</strong> This Cookies Policy is subject to change
          and should be reviewed periodically to ensure it remains current with
          legal requirements and company practices.
        </p>
      </div>
    </div>
  );
}

export default cookies;
