// src/components/ApplicationFormModal.jsx
import React, { useState } from "react";
import Modal from "react-modal";

// Ensure you call this method to bind modal to your appElement
Modal.setAppElement("#root");

const ApplicationFormModal = ({ isOpen, onRequestClose }) => {
  const [name, setName] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [isOver21, setIsOver21] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
    console.log({ name, whatsappNumber, gender, age, email, isOver21 });
    onRequestClose(); // Close the modal
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Application Form"
      className="modal"
      overlayClassName="overlay"
    >
      <h2>Application Form</h2>
      <p className="text-gray-600">Enter details</p>
      <form onSubmit={handleSubmit} className="application-form">
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label>
        <label>
          WhatsApp Number:
          <input
            type="text"
            value={whatsappNumber}
            onChange={(e) => setWhatsappNumber(e.target.value)}
            required
          />
        </label>
        <label>
          Gender:
          <select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
          >
            <option value="">Select</option>
            <option value="Man">Man</option>
            <option value="Woman">Woman</option>
          </select>
        </label>
        <label>
          Age:
          <input
            type="number"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            required
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <label>
          <input
            type="checkbox"
            checked={isOver21}
            onChange={(e) => setIsOver21(e.target.checked)}
            required
          />
          Yes, I am over 21
        </label>
        <button type="submit">Apply Now</button>
      </form>
    </Modal>
  );
};

export default ApplicationFormModal;
