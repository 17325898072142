import React from "react";
import "./css/section7.css";

function Section7() {
  return (
    <div className="section7 text-white">
      <section className="px-4 py-10 md:py-20">
        <div className="w-full max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="sec-title ctx-title mb-3 font-bold">Our services</h2>
            <p className="sec-subtitle ctx-tips">
              We are a remote work-at-home call center for people with busy
              schedules.
            </p>
            <p className="ctx-tips">
              But still want to earn great money while loving life.
            </p>
          </div>
          <div className="w-full mt-5 overflow-x-hidden">
            <ul className="-mx-10 flex flex-wrap">
              <li
                className="w-full md:w-1/2 lg:w-1/3 px-10 py-4 wow animate__bounceInLeft"
                data-wow-duration="5s"
                style={{
                  visibility: "visible",
                  animationDuration: "5s",
                  animationName: "bounceInLeft",
                }}
              >
                <div className="w-full relative">
                  <img
                    src="assets/img/service-bg.png"
                    className="w-full h-auto"
                    alt="Service Background"
                  />
                  <div className="w-full px-10 py-5 absolute left-0 top-0">
                    <img
                      src="assets/img/service-1.svg"
                      className="w-auto h-[100px] mx-auto block"
                      alt="Service 1"
                    />
                    <h6 className="mb-3 text-lg md:text-xl text-orange-500 text-center">
                      <b>Scalability</b>
                    </h6>
                    <article className="text-xs md:text-sm font-bold text-gray-default">
                      <p>
                        The ability to scale services up or down based on the
                        client's needs, whether it’s handling seasonal spikes in
                        call volume or accommodating long-term growth.
                      </p>
                    </article>
                  </div>
                </div>
              </li>

              <li
                className="w-full md:w-1/2 lg:w-1/3 px-10 py-4 wow animate__bounceInRight"
                data-wow-duration="5s"
                style={{
                  visibility: "visible",
                  animationDuration: "5s",
                  animationName: "bounceInRight",
                }}
              >
                <div className="w-full relative">
                  <img
                    src="assets/img/service-bg.png"
                    className="w-full h-auto"
                    alt="Service Background"
                  />
                  <div className="w-full px-10 py-5 absolute left-0 top-0">
                    <img
                      src="assets/img/service-2.svg"
                      className="w-auto h-[100px] mx-auto block wow animate__bounceInLeft"
                      data-wow-duration="3s"
                      style={{
                        visibility: "visible",
                        animationDuration: "3s",
                        animationName: "bounceInLeft",
                      }}
                      alt="Service 2"
                    />
                    <h6 className="mb-3 text-lg md:text-xl text-orange-500 text-center">
                      <b>Data Security and Compliance</b>
                    </h6>
                    <article className="text-xs md:text-sm font-bold text-gray-default">
                      <p>
                        Ensuring robust data protection measures and compliance
                        with relevant regulations (like GDPR or HIPAA) to
                        safeguard customer information and maintain client
                        trust.
                      </p>
                    </article>
                  </div>
                </div>
              </li>

              <li
                className="w-full md:w-1/2 lg:w-1/3 px-10 py-4 wow animate__bounceInRight"
                data-wow-duration="5s"
                style={{
                  visibility: "visible",
                  animationDuration: "5s",
                  animationName: "bounceInRight",
                }}
              >
                <div className="w-full relative">
                  <img
                    src="assets/img/service-bg.png"
                    className="w-full h-auto"
                    alt="Service Background"
                  />
                  <div className="w-full px-10 py-5 absolute left-0 top-0">
                    <img
                      src="assets/img/service-3.gif"
                      className="w-auto h-[100px] mx-auto block"
                      alt="Service 3"
                    />
                    <h6 className="mb-3 text-lg md:text-xl text-orange-500 text-center">
                      <b>Performance Reporting and Analytics</b>
                    </h6>
                    <article className="text-xs md:text-sm font-bold text-gray-default">
                      <p>
                        Providing detailed reports and insights on call center
                        performance, including metrics like average handle time,
                        customer satisfaction scores, and first-call resolution
                        rates, to help clients make informed decisions.
                      </p>
                    </article>
                  </div>
                </div>
              </li>

              <li
                className="w-full md:w-1/2 lg:w-1/3 px-10 py-4 wow animate__bounceInLeft"
                data-wow-duration="5s"
                style={{
                  visibility: "visible",
                  animationDuration: "5s",
                  animationName: "bounceInLeft",
                }}
              >
                <div className="w-full relative">
                  <img
                    src="assets/img/service-bg.png"
                    className="w-full h-auto"
                    alt="Service Background"
                  />
                  <div className="w-full px-10 py-5 absolute left-0 top-0">
                    <img
                      src="assets/img/service-4.svg"
                      className="w-auto h-[100px] mx-auto block"
                      alt="Service 4"
                    />
                    <h6 className="mb-3 text-lg md:text-xl text-orange-500 text-center">
                      <b>Comprehensive Training and Development</b>
                    </h6>
                    <article className="text-xs md:text-sm font-bold text-gray-default">
                      <p>
                        Provide ongoing training to enhance skills in
                        communication, problem-solving, and technology use,
                        ensuring employees feel confident and capable in their
                        roles.
                      </p>
                    </article>
                  </div>
                </div>
              </li>

              <li
                className="w-full md:w-1/2 lg:w-1/3 px-10 py-4 wow animate__bounceInRight"
                data-wow-duration="5s"
                style={{
                  visibility: "visible",
                  animationDuration: "5s",
                  animationName: "bounceInRight",
                }}
              >
                <div className="w-full relative">
                  <img
                    src="assets/img/service-bg.png"
                    className="w-full h-auto"
                    alt="Service Background"
                  />
                  <div className="w-full px-10 py-5 absolute left-0 top-0">
                    <img
                      src="assets/img/service-5.svg"
                      className="w-auto h-[100px] mx-auto block wow animate__bounceInLeft"
                      data-wow-duration="3s"
                      style={{
                        visibility: "visible",
                        animationDuration: "3s",
                        animationName: "bounceInLeft",
                      }}
                      alt="Service 5"
                    />
                    <h6 className="mb-3 text-lg md:text-xl text-orange-500 text-center">
                      <b>Flexible Work Schedules</b>
                    </h6>
                    <article className="text-xs md:text-sm font-bold text-gray-default">
                      <p>
                        Offer flexible scheduling options, including remote work
                        opportunities, to accommodate different lifestyles and
                        improve work-life balance.
                      </p>
                    </article>
                  </div>
                </div>
              </li>

              <li
                className="w-full md:w-1/2 lg:w-1/3 px-10 py-4 wow animate__bounceInRight"
                data-wow-duration="5s"
                style={{
                  visibility: "visible",
                  animationDuration: "5s",
                  animationName: "bounceInRight",
                }}
              >
                <div className="w-full relative">
                  <img
                    src="assets/img/service-bg.png"
                    className="w-full h-auto"
                    alt="Service Background"
                  />
                  <div className="w-full px-10 py-5 absolute left-0 top-0">
                    <img
                      src="assets/img/service-6.svg"
                      className="w-auto h-20 md:h-[100px] mx-auto block"
                      alt="Service 6"
                    />
                    <h6 className="mb-3 text-lg md:text-xl text-orange-500 text-center">
                      <b>Recognition and Incentives</b>
                    </h6>
                    <article className="text-xs md:text-sm font-bold text-gray-default">
                      <p>
                        Implement a system for recognizing and rewarding
                        exceptional performance, whether through bonuses,
                        awards, or public acknowledgment, to boost morale and
                        encourage high-quality work.
                      </p>
                    </article>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Section7;
