import React from "react";
import "./css/section9.css";

function Section9() {
  return (
    <div className="section9 text-white">
      <section className="px-4 py-10 md:py-20">
        <div className="w-full max-w-7xl mx-auto">
          <ul className="-mx-4 text-xl md:text-3xl text-blue-500 font-bold text-center flex flex-wrap">
            <li className="w-full md:w-1/2 lg:w-1/4 p-4">
              <p
                className="wow animate__uislide"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationName: "uiscale",
                }}
              >
                We have several
                <br />
                well satisfied clients.
              </p>
            </li>
            <li className="w-full md:w-1/2 lg:w-1/4 p-4">
              <p
                className="wow animate__uislide"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationName: "uiscale",
                }}
              >
                98%
              </p>
              <p
                className="wow animate__uislide"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationName: "uiscale",
                }}
              >
                Overall Reviews
              </p>
            </li>
            <li className="w-full md:w-1/2 lg:w-1/4 p-4">
              <p
                className="wow animate__uislide"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationName: "uiscale",
                }}
              >
                Thousands of
              </p>
              <p
                className="wow animate__uislide"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationName: "uiscale",
                }}
              >
                Call Completed
              </p>
            </li>
            <li className="w-full md:w-1/2 lg:w-1/4 p-4">
              <p
                className="wow animate__uislide"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationName: "uiscale",
                }}
              >
                Many Many
              </p>
              <p
                className="wow animate__uislide"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationName: "uiscale",
                }}
              >
                Satisfied Business Owners.
              </p>
            </li>
          </ul>
          <h5
            className="my-5 text-xl md:text-3xl text-orange-500 font-bold wow animate__uislide"
            data-wow-duration="2s"
            style={{
              visibility: "visible",
              animationDuration: "2s",
              animationName: "uiscale",
            }}
          >
            <span>Meet our team</span>
          </h5>
          <dl
            className="-mx-4 text-center md:text-left flex flex-wrap wow animate__uislide"
            data-wow-duration="2s"
            style={{
              visibility: "visible",
              animationDuration: "2s",
              animationName: "uiscale",
            }}
          >
            <dt className="w-full md:w-1/2 px-4">
              <h3 className="text-2xl md:text-4xl font-bold">Meet our team</h3>
            </dt>
            <dt className="w-full md:w-1/2 px-4">
              <p className="md:text-xl text-gray-default font-bold">
                "Strong communication and teamwork are essential for maximizing
                each member's strengths, ensuring that the team works together
                effectively to achieve its objectives. By fostering an
                environment of open dialogue and mutual support, teams can
                navigate challenges more efficiently and reach their goals with
                greater success."
              </p>
            </dt>
          </dl>
          <ul className="-mx-4 flex flex-wrap">
            <li className="w-full md:w-1/2 lg:w-1/4 p-4">
              <div className="px-4 relative z-10 add-bg">
                <img
                  src="assets/img//team-1.jpg"
                  className="w-full h-[365px] object-cover rounded-2xl"
                  alt="Team 1"
                />
              </div>
              <div
                className="w-full px-3 pb-10 pt-8 -mt-7 bg-default text-default rounded-3xl wow animate__uislide"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationName: "uiscale",
                }}
              >
                <h5 className="text-black text-xl md:text-3xl font-bold text-center">
                  Karin Necole
                </h5>
                {/* <p className="text-black text-xl md:text-3xl font-bold text-center">
                  Accession year 2010
                </p> */}
              </div>
            </li>
            <li className="w-full md:w-1/2 lg:w-1/4 p-4">
              <div className="px-4 relative z-10 add-bg">
                <img
                  src="assets/img//team-2.jpg"
                  className="w-full h-[365px] object-cover rounded-2xl"
                  alt="Team 2"
                />
              </div>
              <div
                className="w-full px-3 pb-10 pt-8 -mt-7 bg-default text-default rounded-3xl wow animate__uislide"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationName: "uiscale",
                }}
              >
                <h5 className="text-black text-xl md:text-3xl font-bold text-center">
                  Norman Dennis
                </h5>
              </div>
            </li>
            <li className="w-full md:w-1/2 lg:w-1/4 p-4">
              <div className="px-4 relative z-10 add-bg">
                <img
                  src="assets/img//team-3.jpg"
                  className="w-full h-[365px] object-cover rounded-2xl"
                  alt="Team 3"
                />
              </div>
              <div
                className="w-full px-3 pb-10 pt-8 -mt-7 bg-default text-default rounded-3xl wow animate__uislide"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationName: "uiscale",
                }}
              >
                <h5 className="text-black text-xl md:text-3xl font-bold text-center">
                  Ariana Mitchell
                </h5>
              </div>
            </li>
            <li className="w-full md:w-1/2 lg:w-1/4 p-4">
              <div className="px-4 relative z-10 add-bg">
                <img
                  src="assets/img//team-4.jpg"
                  className="w-full h-[365px] object-cover rounded-2xl"
                  alt="Team 4"
                />
              </div>
              <div
                className="w-full px-3 pb-10 pt-8 -mt-7 bg-default text-default rounded-3xl wow animate__uislide"
                data-wow-duration="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationName: "uiscale",
                }}
              >
                <h5 className="text-black text-xl md:text-3xl font-bold text-center">
                  Desta Keith
                </h5>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default Section9;
